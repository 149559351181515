import React, { ReactElement } from 'react'
import { Navbar, NavbarBrand, Nav, NavLink } from 'react-bootstrap';
import printomioLogoSmall from '../img/logo-small.png';
import { FiShare2 } from 'react-icons/fi';
import { FiLogOut } from 'react-icons/fi';
import { useAuth } from '../contexts/AuthContext'

function TopNavigation(): ReactElement {
    const auth = useAuth();

    async function onLogoutClick(e: any) {
        e.preventDefault();
        auth?.logout();
    };

    return (
        <>
            <Navbar bg="light" className="justify-content-between px-4">
                <NavbarBrand href="/dashboard"><img style={{ maxWidth: "100px" }} src={printomioLogoSmall} alt="Printomio" /></NavbarBrand>
                <Nav className="mr-auto">
                    <NavLink href="#"><FiShare2 /></NavLink>
                    <NavLink onClick={onLogoutClick}><FiLogOut /></NavLink>
                </Nav>
            </Navbar>
        </>
    )
}

export default TopNavigation
