import React from 'react'
import '../css/App.css'
import { Container } from 'react-bootstrap'
import AuthProvider from '../contexts/AuthContext'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import Signup from './Signup'
import Dashboard from './Dashboard'
import ProjectAdd from './ProjectAdd'
import ProjectEdit from './ProjectEdit'
import ProjectDetail from './ProjectDetail'
import Login from './Login'
import ForgotPassword from './ForgotPassword'
import PrivateRoute from './PrivateRoute'
import LandingPage from './LandingPage'
import Printomio from './Printomio'
import CampaignDetail from './CampaignDetail'
import CampaignDetailImages from './CampaignDetailImages'
import CampaignDetailUpload from './CampaignDetailUpload'
import RouteNotFound from './RouteNotFound'
import GoogleAuth from './GoogleAuth'

function App() {
  return (
    <Container className="App-Container">
      <div className="w-100">
        <Router>
          <AuthProvider>
            <Switch>
              <Route exact path="/" component={LandingPage} />
              <PrivateRoute path="/dashboard" component={Dashboard} />
              <PrivateRoute exact path="/projects/add" component={ProjectAdd} />
              <PrivateRoute exact path="/projects/:projectId/edit" component={ProjectEdit} />
              <PrivateRoute exact path="/projects/:projectId" component={ProjectDetail} />
              <Route exact path="/projects/:projectId/campaign/:campaignId" component={CampaignDetail} />
              <PrivateRoute exact path="/projects/:projectId/campaign/:campaignId/Images" component={CampaignDetailImages} />
              <PrivateRoute exact path="/projects/:projectId/campaign/:campaignId/Upload" component={CampaignDetailUpload} />
              <Route path="/signup" component={Signup} />
              <Route path="/login" component={Login} />
              <Route path="/forgot-password" component={ForgotPassword} />
              <Route path="/google-auth" component={GoogleAuth} />
              <Route path="/about" component={Printomio} />
              <Route component={RouteNotFound} />
            </Switch>
          </AuthProvider>
        </Router>
      </div>
    </Container>
  );
}

export default App;
