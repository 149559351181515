import React, { useContext } from 'react'
import axios from 'axios'
import { useHistory } from "react-router-dom"

export const AuthContext = React.createContext<AuthContextType | null>(null);

export function useAuth() {
    const auth = useContext(AuthContext);
    const sessionToken = localStorage.getItem('sessionToken');
    const userId = localStorage.getItem('userId');
    if (sessionToken && userId && auth) {
        auth.sessionToken = sessionToken;
        auth.userId = userId;
    }
    return auth
}

const AuthProvider: React.FC<React.ReactNode> = ({ children }) => {
    const [sessionToken, setSessionToken] = React.useState<string | null>(null);
    const [userId, setUserId] = React.useState<string | null>(null);
    const history = useHistory()

    const persistSession = (_userId: string, _sessionToken: string) => {
        //Setting and persisting sessionToken
        setSessionToken(_sessionToken);
        localStorage.setItem('sessionToken', _sessionToken);
        //Setting and persisting userId
        setUserId(_userId);
        localStorage.setItem('userId', _userId);
    }

    const login = (email: string | null, password: string | null): Promise<void> => {
        let baseURL = process.env.REACT_APP_BASE_URL;

        email = email ?? "";
        password = password ?? "";

        const request = axios.get(baseURL + `/parse/login?username=${encodeURIComponent(email)}&password=${encodeURIComponent(password)}`, {
            headers: {
                'X-Parse-Application-Id': process.env.REACT_APP_APPLICATION_ID
            }
        });

        return request
            .then(res => {
                const _sessionToken = res.data.sessionToken;
                const _userId = res.data.objectId;

                //Setting and persisting sessionToken
                setSessionToken(_sessionToken);
                localStorage.setItem('sessionToken', _sessionToken);
                //Setting and persisting userId
                setUserId(_userId);
                localStorage.setItem('userId', _userId);
            })
            .catch(error => { console.error(error); throw error; });
    };

    const logout = () => {
        localStorage.removeItem('sessionToken');
        setSessionToken(null);
        localStorage.removeItem('userId');
        setUserId(null);
        history.push('/login')
    }

    return (
        <AuthContext.Provider value={{ sessionToken, userId, login, logout, persistSession }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;