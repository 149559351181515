import React, { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useAuth } from '../contexts/AuthContext'
import TopNavigation from './TopNavigation'
import { RouteComponentProps, Link } from "react-router-dom"
import { Table } from "react-bootstrap"

type TParams = { projectId: string };

function ProjectDetail({ match }: RouteComponentProps<TParams>) {
    const { t } = useTranslation()
    const auth = useAuth()

    const [project, setProject] = useState<IProjectData>()
    const [campaignURL, setCampaignURL] = useState<string>()


    //Fetching project data based on the given projectID
    useEffect(() => {
        if (auth?.sessionToken && auth?.userId) {
            const headers = {
                'Content-Type': 'application/json',
                'X-Parse-Application-Id': process.env.REACT_APP_APPLICATION_ID ?? "null",
                'X-Parse-Session-Token': auth?.sessionToken ?? "null"
            }
            fetch(process.env.REACT_APP_BASE_URL + `/parse/classes/Project/${encodeURIComponent(match.params.projectId)}`, { headers })
                .then(response => response.json())
                .then(data => {
                    // console.log(data)
                    const projectData: IProjectData = {
                        objectId: data.objectId,
                        userId: data.user?.objectId,
                        name: data.name,
                        description: data.description,
                        createdAt: data.createdAt,
                        updatedAt: data.updatedAt,
                        campaignId: data.campaign?.objectId,
                        productId: data.product?.objectId,
                        maxVotes: data.maxVotes,
                        maxUploads: data.maxUploads,
                        startDate: data.startDate,
                        endDate: data.endDate,
                        voteStartDate: data.voteStartDate
                    };
                    setProject(projectData);
                    setCampaignURL(`/projects/${encodeURIComponent(match.params.projectId)}/campaign/${encodeURIComponent(projectData?.campaignId || "null")}`);
                });
        }
    }, [auth?.sessionToken, auth?.userId, match.params.projectId])

    // function onUpload(file: File, url: string) {
    //     console.log(`File: ${file.name}, URL: ${url}`); //TODO: debug
    // }

    return (
        <div>
            <TopNavigation />
            <section className="container">
                <h2>{t("projectdetail.yourproject")}: {project?.name}</h2>
                <p>Project ID: {match.params.projectId}</p>

                <Table striped bordered hover>
                    <tbody>
                        <tr>
                            <td>CampainID</td>
                            <td>{project?.campaignId}</td>
                        </tr>
                        <tr>
                            <td>createdAt</td>
                            <td>{project?.createdAt}</td>
                        </tr>
                        <tr>
                            <td>name</td>
                            <td>{project?.name}</td>
                        </tr>
                        <tr>
                            <td>objectId</td>
                            <td>{project?.objectId}</td>
                        </tr>
                        <tr>
                            <td>updatedAt</td>
                            <td>{project?.updatedAt}</td>
                        </tr>
                        <tr>
                            <td>userId</td>
                            <td>{project?.userId}</td>
                        </tr>
                    </tbody>
                </Table>

                <div className="d-grid gap-2">
                    {campaignURL && <Link to={campaignURL} className="btn btn-primary">{t("projectdetail.tocampaign")}</Link>}
                    <Link to="/dashboard" className="btn btn-primary">{t("projectdetail.back")}</Link>
                </div>
            </section>
        </div>
    )
}

export default ProjectDetail