import React, { useRef, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useAuth } from '../contexts/AuthContext'
import TopNavigation from './TopNavigation'
import { Form, Button, Alert } from "react-bootstrap"
import { useHistory, RouteComponentProps } from "react-router-dom"
import ProjectAddDates from "./ProjectAddDates"
import "react-multi-carousel/lib/styles.css"
import axios from 'axios'
import '../css/ProjectAdd.css'
import * as MomentRange from "moment-range";
import * as Moment from "moment";
type TParams = { projectId: string };

function ProjectEdit({ match }: RouteComponentProps<TParams>) {

    const { t } = useTranslation()
    const auth = useAuth()
    const [project, setProject] = useState<IProjectData>()

    //Fetching project data based on the given projectID
    useEffect(() => {
        if (auth?.sessionToken && auth?.userId) {
            const headers = {
                'Content-Type': 'application/json',
                'X-Parse-Application-Id': process.env.REACT_APP_APPLICATION_ID ?? "null",
                'X-Parse-Session-Token': auth?.sessionToken ?? "null"
            }
            fetch(process.env.REACT_APP_BASE_URL + `/parse/classes/Project/${encodeURIComponent(match.params.projectId)}`, { headers })
                .then(response => response.json())
                .then(data => {
                    // console.log(data)
                    const projectData: IProjectData = {
                        objectId: data.objectId,
                        userId: data.user?.objectId,
                        name: data.name,
                        description: data.description,
                        createdAt: data.createdAt,
                        updatedAt: data.updatedAt,
                        campaignId: data.campaign?.objectId,
                        productId: data.product?.objectId,
                        maxVotes: data.maxVotes,
                        maxUploads: data.maxUploads,
                        startDate: data.startDate,
                        endDate: data.endDate,
                        voteStartDate: data.voteStartDate
                    };
                    setProject(projectData);
                });
        }
    }, [auth?.sessionToken, auth?.userId, match.params.projectId])

    const history = useHistory()
    const projectnameRef = useRef<HTMLInputElement>(null)
    const descriptionRef = useRef<HTMLTextAreaElement>(null)
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    //@TODO: Piening: Can we remove this?
    // const [addState, setAddState] = useState(0)
    const [products, setProducts] = useState<IProductData[]>(new Array<IProductData>())
    const moment = MomentRange.extendMoment(Moment);
    const initialFormData = {
        product: "",
        maxVotes: Number(3),
        maxUploads: Number(3),
        voteStartDate: moment().add(2, 'weeks').startOf('day').toISOString()
    }
    const [formValues, setFormValues] = useState(initialFormData)
    //@TODO: Piening: Can we remove this?
    // const initialFormStates = {
    //     product: false,
    //     name: false,
    //     maxVotes: 12,
    //     maxUploads: 3,
    //     dateRange: true,
    //     voteStart: moment().add(2, 'weeks').startOf('day').toISOString()
    // }
    //@TODO: Piening: Can we remove this?
    // const [formState, setFormState] = useState(initialFormStates)
    const range = moment.range(moment().startOf('day'), moment().add(4, 'weeks').startOf('day'));
    const [dateStates, setDateStates] = useState({ start: moment().startOf('day').toISOString(), end: moment().add(4, 'weeks').startOf('day').toISOString() })
    const [dateRange, setDateRange] = useState(range)

    //@TODO: Piening: Can we remove this?
    // const responsive = {
    //     superLargeDesktop: {
    //         // the naming can be any, depends on you.
    //         breakpoint: { max: 4000, min: 3000 },
    //         items: 1
    //     },
    //     desktop: {
    //         breakpoint: { max: 3000, min: 1024 },
    //         items: 1
    //     },
    //     tablet: {
    //         breakpoint: { max: 1024, min: 464 },
    //         items: 1
    //     },
    //     mobile: {
    //         breakpoint: { max: 464, min: 0 },
    //         items: 1
    //     }
    // }

    //Having the projects only updated when the sessionToken or userId changes
    useEffect(() => {
        if (auth?.sessionToken && auth?.userId) {
            axios.get(process.env.REACT_APP_BASE_URL + `/parse/classes/Product`, {
                headers: {
                    'X-Parse-Application-Id': process.env.REACT_APP_APPLICATION_ID,
                    'X-Parse-Session-Token': auth.sessionToken
                }
            })
                .then(res => {
                    var _products = new Array<IProductData>();

                    res.data.results.forEach((element: any) => {
                        const newProduct: IProductData = {
                            objectId: element.objectId,
                            name: element.name,
                            createdAt: element.createdAt,
                            updatedAt: element.updatedAt,
                            minImageWidth: element.minImageWidth,
                            minImageHeight: element.minImageHeight
                        };
                        _products.push(newProduct);
                    });

                    setProducts(_products);
                });
        }
    }, [auth?.sessionToken, auth?.userId])

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()
        let baseURL = process.env.REACT_APP_BASE_URL;

        const formData = {
            name: (projectnameRef.current == null) ? "" : projectnameRef.current.value,
            product: {
                __type: "Pointer",
                className: "Product",
                objectId: formValues.product
            },
            description: (descriptionRef.current == null) ? "" : descriptionRef.current.value,
            maxVotes: formValues.maxVotes,
            maxUploads: formValues.maxUploads,
            startDate: {
                __type: "Date",
                iso: new Date(dateStates.start)
            },
            endDate: {
                __type: "Date",
                iso: new Date(dateStates.end)
            },
            voteStartDate: {
                __type: "Date",
                iso: new Date(formValues.voteStartDate)
            }
        }

        setError("")
        setLoading(true)

        if (projectnameRef.current) {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Parse-Application-Id': process.env.REACT_APP_APPLICATION_ID ?? "null",
                    'X-Parse-Session-Token': auth?.sessionToken ?? "null"
                },
                body: JSON.stringify(formData)
            };
            fetch(baseURL + `/parse/classes/Project`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (auth?.sessionToken && auth?.userId) {
                        const headers = {
                            'Content-Type': 'application/json',
                            'X-Parse-Application-Id': process.env.REACT_APP_APPLICATION_ID ?? "null",
                            'X-Parse-Session-Token': auth?.sessionToken ?? "null"
                        }
                        fetch(process.env.REACT_APP_BASE_URL + `/parse/classes/Project/${encodeURIComponent(data.objectId)}`, { headers })
                            .then(response => response.json())
                            .then(data => {
                                // console.log(data)
                                /*                                const projectData: IProjectData = {
                                                                    objectId: data.objectId,
                                                                    userId: data.user?.objectId,
                                                                    name: data.name,
                                                                    description: data.description,
                                                                    createdAt: data.createdAt,
                                                                    updatedAt: data.updatedAt,
                                                                    campaignId: data.campaign?.objectId,
                                                                    productId: data.product?.objectId,
                                                                    maxVotes: data.maxVotes,
                                                                    maxUploads: data.maxUploads,
                                                                    startDate: data.startDate,
                                                                    endDate: data.endDate,
                                                                    voteStartDate: data.voteStartDate
                                                                };*/
                                history.push(`/projects/${encodeURIComponent(data.objectId)}/campaign/${encodeURIComponent(data.campaign?.objectId || "null")}`)
                            });
                    }

                    console.log(data)
                })
                .catch(err => {
                    setError(t("projectadd.error_addingproject"))
                    setLoading(false)
                    console.error('There was an error!', err)
                });
        } else {
            setError(t("projectadd.error_projectempty"))
            setLoading(false)
        }
    }

    //@TODO: Piening: Can we remove this?
    // function handleProductChange(event: React.ChangeEvent<HTMLInputElement>) {
    //     event.persist()
    //     const target = event.target;
    //     const value = target.value;
    //     const name = target.name;
    //     setFormValues({ ...formValues, [name]: value })
    //     setFormState({ ...formState, product: true })
    //     //refreshButtonStates()
    // }

    //@TODO: Piening: Can we remove this?
    // function handleNumberChange(event: React.ChangeEvent<HTMLInputElement>) {
    //     event.persist()
    //     const target = event.target;
    //     const value: number = Number(target.value);
    //     const name = target.name;
    //     setFormValues({ ...formValues, [name]: value })
    //     //refreshButtonStates()
    // }

    function handleDateRangeSelect(value: ReactDateRangePicker.OnSelectCallbackParam) {
        setDateRange(moment.range(value.start, value.end))
        //        console.log(value.start.toString() + " - " + value.end.toString())
        setDateStates({ start: value.start.toString(), end: value.end.toString() })
        const voteStart = value.end
        setFormValues({ ...formValues, voteStartDate: voteStart.subtract((value.end.diff(value.start, 'days') / 2), 'days').toISOString() })
        //refreshButtonStates()
    }

    return (
        <div className="ProjectAdd">
            <TopNavigation />
            <div className="Layer px-4">
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Control type="hidden" name="objectId" value={project?.objectId} />
                    <Form.Group id="projectname" className="mb-3">
                        <Form.Control type="text" name="name" defaultValue={project?.name} ref={projectnameRef} placeholder={t("projectadd.projectname")} required />
                        <Form.Control as="textarea" name="description" defaultValue={project?.description} ref={descriptionRef} rows={3} placeholder={t("projectadd.projectdescription")}></Form.Control>
                    </Form.Group>
                    <Form.Group className="mb-3">
                        <Form.Label>{t("projectadd.maxvotes")}</Form.Label>
                        <Form.Control type="number" name="maxVotes" defaultValue={project?.maxVotes} min="1" step="1" required />
                        <Form.Label>{t("projectadd.maxuploads")}</Form.Label>
                        <Form.Control type="number" name="maxUploads" defaultValue={project?.maxUploads} min="1" step="1" required />
                    </Form.Group>
                    <ProjectAddDates
                        startDate={dateStates.start}
                        endDate={dateStates.end}
                        voteStartDate={formValues.voteStartDate}
                        dateRange={dateRange}
                        onSelect={handleDateRangeSelect}
                        onChangeVote={(date: Date) => {
                            setFormValues({ ...formValues, voteStartDate: date.toISOString() }
                                //refreshButtonStates()
                            )
                        }}
                    />
                    <Button id="formSubmit"
                        className="btn btn-primary float-right"
                        type="submit">
                        {t("projectadd.save")}
                    </Button>
                </Form>
            </div>
        </div>
    )
}

export default ProjectEdit
