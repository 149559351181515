import React, { useCallback, useEffect, useState } from 'react'
import TopNavigation from './TopNavigation'
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { FiUpload } from 'react-icons/fi'
import "react-multi-carousel/lib/styles.css"
import { FileRejection, useDropzone } from "react-dropzone"
import '../css/CampaignDetail.css'
import { Alert, Badge, Button, Form } from 'react-bootstrap';
import FileUploadItemWithProgress from './FileUploadItemWithProgress';

type TParams = {
    projectId: string,
    campaignId: string
};

let currentId = 0;

function getNewId() {
    return ++currentId;
}

function CampaignDetailUpload({ match }: RouteComponentProps<TParams>) {
    const { t } = useTranslation()
    const auth = useAuth()

    const [campaign, setCampaign] = useState<ICampaignData>()
    const [uploadEnabled, setUploadEnabled] = useState(true)
    const [files, setFiles] = useState<UploadableFile[]>([])
    const [curUploads, setCurUploads] = useState(0)
    const [remainingUploads, setRemainingUploads] = useState(0)
    const [agb, setAgb] = useState(false);
    const [copyright, setCopyright] = useState(false);

    const onDrop = useCallback((accFiles: File[], rejFiles: FileRejection[]) => {
        const mappedAcc = accFiles.map((file) => ({ file, errors: [], id: getNewId(), isStarted: false, isUploadCompleted: false, isUploadFailed: false }));
        const mappedRej = rejFiles.map((r) => ({ ...r, id: getNewId(), isStarted: false, isUploadCompleted: false, isUploadFailed: false }));
        setFiles((curr) => [...curr, ...mappedAcc, ...mappedRej]);
    }, []);
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: ['image/*'],
        maxSize: 5242880, // 5MB
    });
    const filesContent = files.map((fileWrapper) => (
        <FileUploadItemWithProgress key={fileWrapper.id} fileWrapper={fileWrapper} campaignId={campaign?.objectId || "null"} onRemove={onRemove} onUploadCompleted={onUploadCompleted} />
    ));

    useEffect(() => {
        let errorCount = files.filter((file) => file.errors.length !== 0).length
        setUploadEnabled(files.length > 0 && errorCount === 0 && agb && copyright && remainingUploads >= 0);
    }, [files, agb, copyright, remainingUploads])

    function onRemove(fileWrapper: UploadableFile) {
        setFiles((curr) => curr.filter((fw) => fw !== fileWrapper));
    }

    function onUploadCompleted(fileWrapper: UploadableFile) {
        //Fetch number of images for this campaign
        if (auth?.sessionToken && auth?.userId) {
            const headers = {
                'Content-Type': 'application/json',
                'X-Parse-Application-Id': process.env.REACT_APP_APPLICATION_ID ?? "null",
                'X-Parse-Session-Token': auth?.sessionToken ?? "null"
            }
            fetch(process.env.REACT_APP_BASE_URL + `/parse/classes/Upload?where={"campaign":{"__type":"Pointer","className":"Campaign","objectId":"${encodeURIComponent(match.params.campaignId)}"}}`, { headers })
                .then(response => response.json())
                .then(data => {
                    setCurUploads(data.results.length);
                });
        }
    }

    function startUploads() {
        files.forEach((fileWrapper) => {
            fileWrapper.isStarted = !fileWrapper.isUploadCompleted;
        })
        setFiles((curr) => curr.filter((fw) => fw.isStarted));
    }
    useEffect(() => {
      //Fetch number of images for this campaign
      if (auth?.sessionToken && auth?.userId) {
        const headers = {
            'Content-Type': 'application/json',
            'X-Parse-Application-Id': process.env.REACT_APP_APPLICATION_ID ?? "null",
            'X-Parse-Session-Token': auth?.sessionToken ?? "null"
        }
        fetch(process.env.REACT_APP_BASE_URL + `/parse/classes/Upload?where={"campaign":{"__type":"Pointer","className":"Campaign","objectId":"${encodeURIComponent(match.params.campaignId)}"}}`, { headers })
            .then(response => response.json())
            .then(data => {
                    setCurUploads(data.results.length);
            });
    }
    }, [auth?.sessionToken, auth?.userId, match.params.campaignId])

    useEffect(() => {
        setRemainingUploads((campaign?.maxUploads || curUploads) - curUploads - files.filter((f) => !f.isUploadCompleted && !f.isUploadFailed).length)
    }, [campaign?.maxUploads, curUploads, files])

    //Fetching campain data based on the given campainID
    useEffect(() => {
        if (auth?.sessionToken && auth?.userId) {
            const headers = {
                'Content-Type': 'application/json',
                'X-Parse-Application-Id': process.env.REACT_APP_APPLICATION_ID ?? "null",
                'X-Parse-Session-Token': auth?.sessionToken ?? "null"
            }
            fetch(process.env.REACT_APP_BASE_URL + `/parse/classes/Campaign/${encodeURIComponent(match.params.campaignId)}?include=images`, { headers })
                .then(response => response.json())
                .then(data => {
                    const campaignData: ICampaignData = {
                        objectId: data.objectId,
                        name: data.name,
                        description: data.description,
                        createdAt: data.createdAt,
                        updatedAt: data.updatedAt,
                        maxVotes: data.maxVotes,
                        maxUploads: data.maxUploads,
                        startDate: data.startDate.iso,
                        endDate: data.endDate.iso,
                        voteStartDate: data.voteStartDate.iso
                    };
                    setCampaign(campaignData);
                });
        }
    }, [auth?.sessionToken, auth?.userId, match.params.campaignId])

    return (
        <>
            <TopNavigation />
            <div className="Layer p-4">
                <h3 className="mb-4">{campaign?.name}</h3>
                <h2 className="text-center mb-2">{t('campaigndetail.shareimages')}</h2>
                <p>{t('campaigndetail.uploadintroduction')}</p>
                <div>
                    <div {...getRootProps({ className: 'dropzone text-center mb-3' })}>
                        <input {...getInputProps()} />
                        <p className=""><FiUpload /> {t("campaigndetail.dropfiles")}</p>
                    </div>
                    <aside>
                        <h3>{t('campaigndetail.uploadedfiles')}</h3>
                        {filesContent}
                        <p className="clearboth"></p>
                    </aside>
                </div>

                {(remainingUploads < 0) && <Alert variant="danger">
                    {t("campaigndetail.max_uploads_exceeded", { maxUploads: campaign?.maxUploads, remainingUploads: (campaign?.maxUploads || 0) - curUploads })}
                </Alert>}

                <div className="mb-4">
                    <Badge className="bg-secondary float-right">{`${curUploads} von ${campaign?.maxUploads} Bildern`}</Badge>
                    <div className="clearboth"></div>
                </div>

                <p>{t("campaigndetail.agree_processing")}</p>
                <Form.Group className="mb-3" id="formGridCheckbox">
                    <Form.Check type="checkbox" id="agb" name="agb" onChange={(e) => (setAgb(e.target.checked))} label={t('campaigndetail.upload_agb_consent')} />
                    <Form.Check type="checkbox" id="copyright" name="copyright" onChange={(e) => (setCopyright(e.target.checked))} label={t('campaigndetail.upload_copyright_consent')} />
                </Form.Group>

                <div>
                    <Button className="btn btn-primary rounded-pill mt-2" disabled={!uploadEnabled} onClick={() => (startUploads())}>{t("campaigndetail.upload")}</Button>
                </div>
                <div>
                    <Link className="btn btn-primary rounded-pill mt-2" to={`/projects/${encodeURIComponent(match.params.projectId)}/campaign/${encodeURIComponent(match.params.campaignId)}`}>{t("campaigndetail.back_to_project")}</Link>
                </div>
            </div>
        </>
    )
}

export default CampaignDetailUpload
