import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import '../css/CampaignDetailImages.css'
import { IoIosArrowBack } from 'react-icons/io'
type TParams = {
    projectId: string,
    campaignId: string
};

function CampaignDetailCarousel({ match }: RouteComponentProps<TParams>) {
    const { t } = useTranslation()
    const auth = useAuth()

    const [campaign, setCampaign] = useState<ICampaignData>()
    const [images, setImages] = useState<IImageData[]>(new Array<IImageData>());

    //Fetching campain data based on the given campainID
    useEffect(() => {
        if (auth?.sessionToken && auth?.userId) {
            const headers = {
                'Content-Type': 'application/json',
                'X-Parse-Application-Id': process.env.REACT_APP_APPLICATION_ID ?? "null",
                'X-Parse-Session-Token': auth?.sessionToken ?? "null"
            }
            fetch(process.env.REACT_APP_BASE_URL + `/parse/classes/Campaign/${encodeURIComponent(match.params.campaignId)}?include=images`, { headers })
                .then(response => response.json())
                .then(data => {
                    //Collect campaign data
                    const campaignData: ICampaignData = {
                        objectId: data.objectId,
                        name: data.name,
                        description: data.description,
                        createdAt: data.createdAt,
                        updatedAt: data.updatedAt,
                        maxVotes: data.maxVotes,
                        maxUploads: data.maxUploads,
                        startDate: data.startDate.iso,
                        endDate: data.endDate.iso,
                        voteStartDate: data.voteStartDate.iso
                    };
                    setCampaign(campaignData);

                    //Collect image data
                    var _images = new Array<IImageData>();
                    data.images.forEach((element: any) => {
                        const newImage: IImageData = {
                            imageId: element.objectId,
                            name: element.file.name,
                            url: element.file.url,
                            largeName: element.largeFile.name,
                            votes: element.votes,
                            ownVote: false,
                            ownVoteId: ""
                        };
                        _images.push(newImage);
                    });

                    setImages(_images);
                });
        }
    }, [auth?.sessionToken, auth?.userId, match.params.campaignId])

    return (
        <>
            <div className="Layer p-4">
                <h3>{campaign?.name}</h3>
                <Link className="backLink" to={`/projects/${encodeURIComponent(match.params.projectId)}/campaign/${encodeURIComponent(match.params.campaignId)}`}><IoIosArrowBack /> {t("campaigndetail.imageview_backlink")}</Link>
                {(images.length > 0) && <div className="imageList">
                    {images.map((image: IImageData) => (
                        <div key={`imagediv_${image.imageId}`} style={{ textAlign: "center" }}>
                            <img src={process.env.REACT_APP_BASE_URL + `/parse/files/printomio/${encodeURIComponent(image.largeName)}`} alt={image.largeName} className="mb-1 img-responsive" />
                        </div>
                    ))}
                </div>}
            </div>
        </>
    )
}

export default CampaignDetailCarousel
