import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaEnvelope, FaFacebook, FaGoogle, FaMicrosoft } from 'react-icons/fa';
import { Link } from 'react-router-dom';

interface Props {
    campaignURL: string
}

function RegistrationOptions({ campaignURL }: Props) {
    const { t } = useTranslation()

    const [loginWithGoogleLink, setLoginWithGoogleLink] = useState("/dashboard")
    const [relativeCampaignURL, setRelativeCampaignURL] = useState("/dashboard")

    useEffect(() => {
        //Build relative campaign URL
        let appURL = process.env.REACT_APP_LOCAL_BASE_URL || "undefined";
        let relURL = campaignURL.replace(appURL, '');
        setRelativeCampaignURL(relURL)

        //Fetch GoogleSignInLink
        let baseURL = process.env.REACT_APP_BASE_URL || "undefined";

        const formData = {
            redirectTo: relURL
        }

        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'X-Parse-Application-Id': process.env.REACT_APP_APPLICATION_ID ?? "null"
            },
            body: JSON.stringify(formData)
        };
        fetch(baseURL + `/parse/functions/getGoogleSignInLink`, requestOptions)
            .then(response => response.json())
            .then(data => {
                setLoginWithGoogleLink(data.result)
            })
            .catch(err => {
                console.error('Cant fetch Google SignIn Link!', err)
            })
    }, [campaignURL])

    return <>
        <div className="w-100 text-center mt-2">
            {t("login.no_account_yet")} <Link to={`/signup?redirect-url=${encodeURIComponent(relativeCampaignURL)}`}>{t("login.register_now")}</Link>
        </div>
        <div className="w-100 text-center mt-2">
            <a href={`/signup?redirect-url=${encodeURIComponent(relativeCampaignURL)}`} className="d-block btn btn-primary mb-2 rounded-pill"><FaEnvelope /> {t("login.register_now")}</a><br />
            <a href={loginWithGoogleLink} target="_blank" rel="noreferrer" className="d-block btn btn-primary mb-2 rounded-pill"><FaGoogle /> {t("login.google")}</a><br />
            <a href="/login" className="d-block btn btn-primary mb-2 rounded-pill"><FaFacebook /> {t("login.facebook")}</a><br />
            <a href="/login" className="d-block btn btn-primary mb-2 rounded-pill"><FaMicrosoft /> {t("login.microsoft")}</a>
        </div>
    </>;
}

export default RegistrationOptions;
