import React, { useRef, useState } from 'react'
import { useTranslation } from "react-i18next"
import { Form, Button, Alert } from 'react-bootstrap'
import { Link, useLocation } from "react-router-dom"
import printomioLogo from '../img/logo.png';

// A custom hook that builds on useLocation to parse the query string
function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Signup() {
    const { t } = useTranslation()
    const nameRef = useRef<HTMLInputElement>(null)
    const emailRef = useRef<HTMLInputElement>(null)
    const passwordRef = useRef<HTMLInputElement>(null)
    const passwordConfirmRef = useRef<HTMLInputElement>(null)
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [registered, setRegistered] = useState(false)
    let query = useQuery();

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()

        setError("")
        setLoading(true)

        if (emailRef.current && passwordRef.current && passwordConfirmRef.current) {
            if (passwordRef.current.value !== passwordConfirmRef.current.value) {
                setLoading(false)
                setError(t("signup.password_mismatch"))
            } else if (passwordRef.current.value.length < parseInt(process.env.REACT_APP_MIN_PW_LEN || "12")) {
                setLoading(false)
                setError(t("signup.password_too_short", { n: process.env.REACT_APP_MIN_PW_LEN }))
            } else {
                try {
                    let baseURL = process.env.REACT_APP_BASE_URL;

                    const formData = {
                        //Select email as name, if name is empty
                        name: nameRef?.current?.value || emailRef.current.value,
                        email: emailRef.current.value,
                        password: passwordRef.current.value,
                    }

                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'X-Parse-Application-Id': process.env.REACT_APP_APPLICATION_ID ?? "null"
                        },
                        body: JSON.stringify(formData)
                    };
                    fetch(baseURL + `/parse/functions/signInWithEmail`, requestOptions)
                        .then(response => {
                            setRegistered(true);
                        })
                        .then(data => console.log(`data: ${data}`))
                        .catch(err => {
                            setError(t("signup.error"))
                            setLoading(false)
                            console.error('There was an error!', err)
                        });
                } catch {
                    setLoading(false)
                    setError(t("signup.error"))
                }
            }
        }
    }

    return (
        <>
            <div className="px-4">
                <img className="logo" style={{ maxWidth: "300px", margin: "0 auto", display: "block" }} src={printomioLogo} alt="Printomio - Social Printing" />
            </div>
            {!registered &&
                <div className="Layer px-4">
                    <h2 className="text-center mb-4">{t("signup.registration")}</h2>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Form onSubmit={handleSubmit}>
                        <Form.Group id="name" className="mb-3">
                            <Form.Control type="text" ref={nameRef} placeholder={t("signup.name")} required />
                        </Form.Group>
                        <Form.Group id="email" className="mb-3">
                            <Form.Control type="email" ref={emailRef} placeholder={t("signup.email")} required />
                        </Form.Group>
                        <Form.Group id="password" className="mb-3">
                            <Form.Control type="password" ref={passwordRef} placeholder={t("signup.password")} required />
                        </Form.Group>
                        <Form.Group id="password" className="mb-3">
                            <Form.Control type="password" ref={passwordConfirmRef} placeholder={t("signup.password_confirm")} required />
                        </Form.Group>

                        <Button type="submit" disabled={loading} className="w-100">
                            {t("signup.register")}
                        </Button>
                    </Form>
                    <div className="w-100 text-center mt-2">
                        {t("signup.allready_registered")} <Link to="/login">{t("signup.login_here")}</Link>
                    </div>
                </div>}
            {registered &&
                <div className="Layer px-4">
                    <p>Die Registierung wurde erfolgreich übermittelt. Bitte prüfen Sie ihr Email-Postfach und bestätigen Sie den dort enthaltenen Link um den Registrierungs-Prozess abzuschliessen.</p>
                    <Link className="btn btn-secondary" to={`/login?redirect-url=${encodeURIComponent(query.get("redirect-url") || "/dashboard")}`}>{t("signup.back_to_login")}</Link>
                </div>}
        </>
    )
}

export default Signup
