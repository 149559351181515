import '../css/LandingPage.css';
import printomioLogo from '../img/logo.png';
import { Row, Col } from 'react-bootstrap';
import { FiUploadCloud } from 'react-icons/fi';
import { FiThumbsUp } from 'react-icons/fi';
import { FaBox } from 'react-icons/fa';
import { useTranslation } from "react-i18next";

function LandingPage() {
    const { t } = useTranslation();

    return (
        <div className="LandingPage">
            <div className="px-4">
                <img className="logo" style={{ maxWidth: "300px", margin: "0 auto", display: "block" }} src={printomioLogo} alt="Printomio - Social Printing" />
            </div>
            <div className="Layer px-4">
                <p className="text-center">{t("landing.intro")}</p>
                <Row>
                    <Col className="text-end" xs={9}>
                        <h2 className="text-uppercase">{t("landing.collecting")}</h2>
                        <p>{t("landing.share_with_friends")}</p>
                    </Col>
                    <Col className="d-flex align-items-center justify-content-center">
                        <FiUploadCloud className="icon" />
                    </Col>
                </Row>
                <Row>
                    <Col className="d-flex align-items-center justify-content-center">
                        <FiThumbsUp className="icon" />
                    </Col>
                    <Col xs={9}>
                        <h2 className="text-uppercase">{t("landing.voting")}</h2>
                        <p>{t("landing.vote_together")}</p>
                    </Col>
                </Row>
                <Row>
                    <Col className="text-end" xs={9}>
                        <h2 className="text-uppercase">{t("landing.order")}</h2>
                        <p>{t("landing.order_finished_product")}</p>
                    </Col>
                    <Col className="d-flex align-items-center justify-content-center">
                        <FaBox className="icon" />
                    </Col>
                </Row>
                <a href="/dashboard" className="d-block btn btn-primary mb-2 rounded-pill">{t("landing.start_now")}</a>
                <a href="/about" className="d-block btn btn-secondary mb-2 rounded-pill">{t("landing.about_printomio")}</a>
            </div>
        </div>
    )
}

export default LandingPage
