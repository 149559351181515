import React, { useEffect, useState } from 'react'
import TopNavigation from './TopNavigation'
import { useTranslation } from 'react-i18next';
import { Link, RouteComponentProps } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Col, ProgressBar, Row, Modal, Button } from 'react-bootstrap';
import { FiCopy, FiEdit2, FiLink, FiShare2, FiUpload } from 'react-icons/fi'
import { HiOutlineHeart, HiHeart } from 'react-icons/hi'
import Moment from "moment";
import '../css/CampaignDetail.css'
import RegistrationOptions from './RegistrationOptions';

type TParams = {
    projectId: string,
    campaignId: string
};

function CampaignDetail({ match }: RouteComponentProps<TParams>) {
    const { t } = useTranslation()
    const auth = useAuth()

    const [project, setProject] = useState<IProjectData>()
    const [campaign, setCampaign] = useState<ICampaignData>()
    const [images, setImages] = useState<IImageData[]>(new Array<IImageData>());
    const [ownImageVotes, setOwnImageVotes] = useState<Map<string, string>>();
    const [voteChanged, setVoteChanged] = useState<boolean>(false);
    const [numVotesLeft, setNumVotesLeft] = useState<number>(0);
    const [maxVotesReachedIsOpen, setMaxVotesReachedIsOpen] = useState<boolean>(false);
    const [loggedIn, setLoggedIn] = useState<boolean>(false);
    const location = window.location.href;
    const [navigatorShare, setNavigatorShare] = useState<boolean>(false);
    useEffect(() => {
        setLoggedIn(auth?.sessionToken ? true : false);
        setNavigatorShare(typeof navigator.share !== 'undefined');
    }, [auth?.sessionToken])

    //Fetching own votes (only when logged in)
    useEffect(() => {
        if (loggedIn) {
            setVoteChanged(false);
            if (auth?.sessionToken && auth?.userId) {
                const headers = {
                    'Content-Type': 'application/json',
                    'X-Parse-Application-Id': process.env.REACT_APP_APPLICATION_ID ?? "null",
                    'X-Parse-Session-Token': auth?.sessionToken ?? "null"
                }
                fetch(process.env.REACT_APP_BASE_URL + `/parse/classes/Vote`, { headers })
                    .then(response => response.json())
                    .then(data => {
                        var _votedImages = new Map<string, string>()
                        data.results.forEach((element: any) => {
                            //Store image id and vote id in a map
                            _votedImages.set(element.image.objectId, element.objectId)
                        });
                        setOwnImageVotes(_votedImages);
                    });
                fetch(process.env.REACT_APP_BASE_URL + `/parse/classes/Project/${encodeURIComponent(match.params.projectId)}`, { headers })
                    .then(response => response.json())
                    .then(data => {
                        const projectData: IProjectData = {
                            objectId: data.objectId,
                            userId: data.user?.objectId,
                            name: data.name,
                            description: data.description,
                            createdAt: data.createdAt,
                            updatedAt: data.updatedAt,
                            campaignId: data.campaign?.objectId,
                            productId: data.product?.objectId,
                            maxVotes: data.maxVotes,
                            maxUploads: data.maxUploads,
                            startDate: data.startDate,
                            endDate: data.endDate,
                            voteStartDate: data.voteStartDate
                        };
                        setProject(projectData);
                    });
            }
        }
    }, [auth?.sessionToken, auth?.userId, loggedIn, voteChanged])

    //Fetching campain data based on the given campainID
    useEffect(() => {

        const headers: any = {
            'Content-Type': 'application/json',
            'X-Parse-Application-Id': process.env.REACT_APP_APPLICATION_ID ?? "null"
        }
        //Only add sessionToken if we are logged in, this page is publicly available
        if (auth?.sessionToken) {
            headers['X-Parse-Session-Token'] = auth.sessionToken;
        }

        fetch(process.env.REACT_APP_BASE_URL + `/parse/classes/Campaign/${encodeURIComponent(match.params.campaignId)}?include=images`, { headers })
            .then(response => response.json())
            .then(data => {
                //Collect campaign data
                const campaignData: ICampaignData = {
                    objectId: data.objectId,
                    name: data.name,
                    description: data.description,
                    createdAt: data.createdAt,
                    updatedAt: data.updatedAt,
                    maxVotes: data.maxVotes,
                    maxUploads: data.maxUploads,
                    startDate: data.startDate?.iso,
                    endDate: data.endDate?.iso,
                    voteStartDate: data.voteStartDate?.iso
                };
                setCampaign(campaignData);

                //Set vote count
                setNumVotesLeft(campaignData.maxVotes - (ownImageVotes?.size || 0));

                //Collect image data
                var _images = new Array<IImageData>();
                data.images.forEach((element: any) => {
                    const newImage: IImageData = {
                        imageId: element.objectId,
                        name: element.file.name,
                        url: element.file.url,
                        largeName: element.largeFile.name,
                        votes: element.votes,
                        ownVote: ownImageVotes?.has(element.objectId) || false,
                        ownVoteId: ownImageVotes?.get(element.objectId) || ""
                    };
                    _images.push(newImage);
                });

                setImages(_images);
            });

    }, [auth?.sessionToken, auth?.userId, match.params.campaignId, ownImageVotes])

    const uploadDuration = Moment(campaign?.voteStartDate).diff(Moment(campaign?.startDate), 'days')
    const uploadRemaining = Moment(campaign?.voteStartDate).diff(Moment(), 'days')
    const votingDuration = Moment(campaign?.endDate).diff(Moment(campaign?.voteStartDate), 'days')
    const votingRemaining = Moment(campaign?.endDate).diff(Moment(), 'days')
    const uploadProgress = Math.round((1 - (uploadRemaining / uploadDuration)) * 100)
    const votingProgress = Math.round((1 - (votingRemaining / votingDuration)) * 100)
    var status = 'unknown'
    if (uploadProgress >= 0 && uploadProgress <= 100) {
        status = 'upload'
    } else if (votingProgress >= 0 && votingProgress <= 100) {
        status = 'voting'
    } else if (votingProgress > 100) {
    status = 'order'
}
// status = 'voting' //TODO: Debug

    function voteUp(e: React.MouseEvent<SVGElement>, imageId: string) {
        e.preventDefault();
        if (numVotesLeft > 0) {
            if (auth?.sessionToken && auth?.userId) {
                try {
                    let baseURL = process.env.REACT_APP_BASE_URL;

                    const formData = {
                        image: {
                            __type: "Pointer",
                            className: "Image",
                            objectId: imageId
                        }
                    }

                    const requestOptions = {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'X-Parse-Application-Id': process.env.REACT_APP_APPLICATION_ID ?? "null",
                            'X-Parse-Session-Token': auth.sessionToken
                        },
                        body: JSON.stringify(formData)
                    };
                    fetch(baseURL + `/parse/classes/Vote`, requestOptions)
                        .then(response => {
                            setVoteChanged(true)
                        })
                        .catch(err => {
                            console.error('There was an error!', err)
                        });
                } catch {
                    console.log("Error submitting vote.");
                }
            }
        } else {
            setMaxVotesReachedIsOpen(true)
        }
    }

    function voteDown(e: React.MouseEvent<SVGElement>, imageId: string, voteId: string) {
        e.preventDefault();
        if (auth?.sessionToken && auth?.userId) {
            try {
                let baseURL = process.env.REACT_APP_BASE_URL;

                const requestOptions = {
                    method: 'DELETE',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Parse-Application-Id': process.env.REACT_APP_APPLICATION_ID ?? "null",
                        'X-Parse-Session-Token': auth.sessionToken
                    }
                };
                fetch(baseURL + `/parse/classes/Vote/${encodeURIComponent(voteId)}`, requestOptions)
                    .then(response => {
                        setVoteChanged(true)
                    })
                    .catch(err => {
                        console.error('There was an error!', err)
                    });
            } catch {
                console.log("Error deleting vote.");
            }
        }
    }

    const hideVoteModal = () => {
        setMaxVotesReachedIsOpen(false);
    };

    function shareCampaign(cTitle: string, cDescription: string, cUrl: string) {
        console.log(cTitle + " - " + cDescription + " - " + process.env.REACT_APP_BASE_URL + cUrl);
        try {
            navigator
                .share({
                    title: cTitle,
                    text: cDescription,
                    url: process.env.REACT_APP_BASE_URL + cUrl
                })
                .then(() => console.log('Successful share! 🎉'))
                .catch(err => console.error(err));
        } catch (err) {
            console.error(err)
        }
    }
    return (
        <>
            <TopNavigation />
            <div className="Layer p-4">
                <h1 className="text-center">{campaign?.name}{loggedIn && (project?.userId===auth?.userId) && <Link to={`/projects/${project?.objectId}/edit`} className="float-right editProject"><FiEdit2 /></Link>}</h1>
                <p>{campaign?.description}</p>
                <Row className="mt-4">
                    <Col><h3>Projektfortschritt</h3></Col>
                    <Col style={{ textAlign: "right" }}><FiUpload></FiUpload> {images.length}</Col>
                </Row>
                <Row className="mb-4">
                    <Col className="px-1" md="5" xs="5"><ProgressBar now={uploadProgress} /><span className={(status === "upload") ? "mt-1 progressTitle active" : "mt-1 progressTitle"}>1. Sammeln{(uploadRemaining >= 0) ? ": " + uploadRemaining + " Tage" : ""}</span></Col>
                    <Col className="px-1" md="4" xs="4"><ProgressBar now={votingProgress} /><span className={(status === "voting") ? "mt-1 progressTitle active" : "mt-1 progressTitle"}>2. Bewerten{(votingRemaining >= 0) ? ": " + votingRemaining + " Tage" : ""}</span></Col>
                    <Col className="px-1" md="3" xs="3"><ProgressBar now={0} /><span className="mt-1 progressTitle">3. Bestellen</span></Col>
                </Row>
                <Row className="mb-4">
                    <Col>
                    {(status === "upload") && <p>{t("campaigndetail.uploadNotice")}</p>}
                    {(status === "voting") && <p>{t("campaigndetail.votingNotice")}</p>}
                    {(status === "order") && <p>{t("campaigndetail.orderNotice")}</p>}
                    </Col>
                </Row>
                {loggedIn && (project?.userId===auth?.userId) && <Row className="mb-4">
                    <Col>
                    <p className="hidden">{t("campaigndetail.creationNotice")}</p>
                    {navigatorShare && <p>{t("campaigndetail.shareProject")}<Button className="rounded-pill float-right" onClick={() => shareCampaign(`${campaign?.name}`, `${campaign?.description}`, `/projects/${encodeURIComponent(match.params.projectId)}/campaign/${encodeURIComponent(match.params.campaignId)}`)}><FiShare2 /> {t("general.share")}</Button></p>}
                    <p><FiLink /> {location} <Button className="rounded-pill float-right" onClick={() => navigator.clipboard.writeText(window.location.href)} ><FiCopy /> {t("general.copy")}</Button></p>
                    </Col>
                </Row>}
                {loggedIn && <Row className={(status === "upload") ? "" : "hidden"}>
                    <Col md="12" xs="12" className="text-center mb-4">
                        <p>Teile Deine Bilder</p>
                        <Link to={`/projects/${encodeURIComponent(match.params.projectId)}/campaign/${encodeURIComponent(match.params.campaignId)}/Upload`} className="btn btn-primary rounded-pill px-4"><FiUpload /> {t("campaigndetail.uploadlink")}</Link>
                    </Col>
                </Row>}
                {!loggedIn && <Row>
                    <Col md="12" xs="12" className="text-center mb-4">
                        <p>{t("campaigndetail.login_to_upload")}</p>
                        <Link to={`/login?redirect-url=${encodeURIComponent(window.location.href)}`} className="btn btn-primary rounded-pill px-4"><FiUpload /> {t("campaigndetail.login")}</Link>
                    </Col>
                    <RegistrationOptions campaignURL={window.location.href} />
                </Row>}
                {(images.length > 0) && <div>
                    <Row className="mb-4">
                        <Col md={(status === "voting") ? 6 : 12} xs={(status === "voting") ? 6 : 12}>
                            <h3>{t("campaigndetail.imagelist_" + status)}</h3>
                        </Col>
                        {(status === "voting") && <Col className="text-end" md="6" xs="6">
                            {t("campaigndetail.votes_left", { n: numVotesLeft })}
                        </Col>}
                    </Row>

                    <Row>
                        {images.map((image: IImageData) => (
                            <Col key={image.name} md="6" xs="6" className="imageTile">
                                <Link to={`/projects/${encodeURIComponent(match.params.projectId)}/campaign/${encodeURIComponent(match.params.campaignId)}/Images`}>
                                    <img style={{ maxWidth: "100%" }} src={process.env.REACT_APP_BASE_URL + `/parse/files/printomio/${encodeURIComponent(image.name)}`} alt={image.name} />
                                </Link>
                                {(status === "voting") ? <span className="voting">{!image.ownVote && <HiOutlineHeart onClick={(e) => voteUp(e, image.imageId)} />}{image.ownVote && <HiHeart onClick={(e) => voteDown(e, image.imageId, image.ownVoteId)} />}{image.votes}</span> : ""}
                            </Col>
                        ))}
                    </Row>
                </div>}

                {loggedIn && <div>
                    <Link className="btn btn-primary rounded-pill mt-2 hidden" to={`/projects/${encodeURIComponent(match.params.projectId)}`}>{t("campaigndetail.backtoproject")}</Link>
                </div>}
                {loggedIn && <div>
                    <Link className="btn btn-primary rounded-pill mt-2" to="/dashboard">{t("campaigndetail.backtoprojectlist")}</Link>
                </div>}
            </div>

            <Modal show={maxVotesReachedIsOpen} onHide={hideVoteModal}>
                <Modal.Header>
                    <Modal.Title>{t("campaigndetail.max_votes_reached")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("campaigndetail.max_votes_reached_explanation")}</Modal.Body>
                <Modal.Footer>
                    <button className="btn btn-primary rounded-pill" onClick={hideVoteModal}>Ok</button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default CampaignDetail
