import React from 'react'
import { Link } from "react-router-dom"
import { useTranslation } from 'react-i18next';

function Dashboard() {
    const { t } = useTranslation()

    return (
        <>
            <div className="Layer p-4">
                {t("routenotfound.message")}
                <div>
                    <Link className="btn btn-primary rounded-pill mt-2" to={`/login/`}>{t("routenotfound.login_here")}</Link>
                </div>
            </div>
        </>
    )
}

export default Dashboard
