import '../css/ProjectItem.css';
import React, { ReactElement, useState, useEffect } from 'react'
import { useAuth } from '../contexts/AuthContext';
import { Link } from "react-router-dom"
import { Row, Col, Popover, PopoverTitle, PopoverContent, OverlayTrigger, Button } from "react-bootstrap"
import Moment from 'moment';
import { FiShare2, FiStar, FiUpload, FiThumbsUp } from 'react-icons/fi';

interface Props {
    project: IProjectData
}

function ProjectItem({ project }: Props): ReactElement {
    Moment.locale('de');
    // const productImg = require('../img/'+project.productId+'.jpg');
    // const location = window.location.href;
    const auth = useAuth()
    const [loggedIn, setLoggedIn] = useState<boolean>(false);
    const [loggedInUser, setLoggedInUser] = useState<string | null | undefined>('');
    const [navigatorShare, setNavigatorShare] = useState<boolean>(false);

    //APiening: Is this really needed here? Looks like a fragment from the login component
    useEffect(() => {
        setLoggedIn(auth?.sessionToken ? true : false);
        setLoggedInUser(auth?.userId);
        setNavigatorShare(typeof navigator.share !== 'undefined');
    }, [auth?.sessionToken, auth?.userId])

    function shareCampaign(cTitle: string, cDescription: string, cUrl: string) {
        console.log(cTitle + " - " + cDescription + " - " + process.env.REACT_APP_BASE_URL + cUrl);
        try {
            navigator
                .share({
                    title: cTitle,
                    text: cDescription,
                    url: process.env.REACT_APP_BASE_URL + cUrl
                })
                .then(() => console.log('Successful share! 🎉'))
                .catch(err => console.error(err));
        } catch (err) {
            console.error(err)
        }
    }

    return (
        <>
            <Row className="projectItem">
                <Col xs="4" className={"productImage productImage-" + project.productId}>
                    <Link to={`/projects/${project.objectId}/campaign/${project.campaignId}`}></Link>
                </Col>
                <Col xs="8" className="projectInfo">
                    <OverlayTrigger
                        trigger={['hover', 'focus']}
                        key='top'
                        placement='top'
                        overlay={
                            <Popover id={`popover-positioned-top`}>
                                <PopoverTitle>weitere Projektinformationen</PopoverTitle>
                                <PopoverContent>
                                    <p>UserID: {project.userId}, CampaignID: {project.campaignId}</p>
                                </PopoverContent>
                            </Popover>
                        }
                    >
                        <h3><Link to={`/projects/${project.objectId}/campaign/${project.campaignId}`}>{project.name}</Link></h3>
                    </OverlayTrigger>
                    <span className="ProjectItem-Status"><FiStar /> {Moment(project.createdAt.toString()).format('DD.MM.YYYY hh:mm')}</span><br />
                    <span className="ProjectItem-Status"><FiUpload /> {Moment(project.startDate).format('DD.MM.YYYY')} - {Moment(project.voteStartDate).format('DD.MM.YYYY')}</span><br />
                    <span className="ProjectItem-Status"><FiThumbsUp /> {Moment(project.voteStartDate).format('DD.MM.YYYY')} - {Moment(project.endDate).format('DD.MM.YYYY')}</span><br />
                    {navigatorShare && <Button className="btn btn-primary mt-2 rounded-pill btn-sm" onClick={() => shareCampaign(`${project.name}`, `${project.description}`, `/projects/${project.objectId}/campaign/${project.campaignId}`)}><FiShare2 /> Teilen</Button>}

                </Col>
            </Row>
        </>
    )
}

export default ProjectItem
