import React, { ReactElement } from 'react'
import ProjectItem from './ProjectItem'

interface Props {
    projects: IProjectData[]
}

function ProjectList({projects}: Props): ReactElement {
    return (
        <>
            {projects.map((project: IProjectData) => (
                <ProjectItem key={project.objectId} project={project}/>
            ))}
        </>
    )
}

export default ProjectList
