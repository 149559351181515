import React, { useRef, useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useAuth } from '../contexts/AuthContext'
import TopNavigation from './TopNavigation'
import { Link, useHistory } from "react-router-dom"
import { Form, Button, Alert } from "react-bootstrap"
import ProjectAddData from "./ProjectAddData"
import ProjectAddType from "./ProjectAddType"
import "react-multi-carousel/lib/styles.css"
import axios from 'axios'
import '../css/ProjectAdd.css'
import * as MomentRange from "moment-range";
import * as Moment from "moment";

function ProjectAdd() {
    const { t } = useTranslation()
    const auth = useAuth()
    const history = useHistory()
    const projectnameRef = useRef<HTMLInputElement>(null)
    const descriptionRef = useRef<HTMLTextAreaElement>(null)
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const [addState, setAddState] = useState(0)
    const [products, setProducts] = useState<IProductData[]>(new Array<IProductData>())
    const moment = MomentRange.extendMoment(Moment);
    const initialFormData = {
        product: "",
        maxVotes: Number(3),
        maxUploads: Number(3),
        voteStartDate: moment().add(2, 'weeks').startOf('day').toISOString()
    }
    const [formValues, setFormValues] = useState(initialFormData)
    const initialFormStates = {
        product: false,
        name: false,
        maxVotes: 12,
        maxUploads: 3,
        dateRange: true,
        voteStart: moment().add(2, 'weeks').startOf('day').toISOString()
    }
    const [formState, setFormState] = useState(initialFormStates)
    //const range = moment.range(moment().startOf('day'), moment().add(4, 'weeks').startOf('day'));
    const [dateStates, setDateStates] = useState({ start: moment().startOf('day').toISOString(), end: moment().add(4, 'weeks').startOf('day').toISOString() })
    //const [dateRange, setDateRange] = useState(range)

    //APiening: Does not seem to be used. Should we remove it?
    // const responsive = {
    //     superLargeDesktop: {
    //         // the naming can be any, depends on you.
    //         breakpoint: { max: 4000, min: 3000 },
    //         items: 1
    //     },
    //     desktop: {
    //         breakpoint: { max: 3000, min: 1024 },
    //         items: 1
    //     },
    //     tablet: {
    //         breakpoint: { max: 1024, min: 464 },
    //         items: 1
    //     },
    //     mobile: {
    //         breakpoint: { max: 464, min: 0 },
    //         items: 1
    //     }
    // }

    //Having the projects only updated when the sessionToken or userId changes
    useEffect(() => {
        if (auth?.sessionToken && auth?.userId) {
            axios.get(process.env.REACT_APP_BASE_URL + `/parse/classes/Product`, {
                headers: {
                    'X-Parse-Application-Id': process.env.REACT_APP_APPLICATION_ID,
                    'X-Parse-Session-Token': auth.sessionToken
                }
            })
                .then(res => {
                    var _products = new Array<IProductData>();

                    res.data.results.forEach((element: any) => {
                        const newProduct: IProductData = {
                            objectId: element.objectId,
                            name: element.name,
                            createdAt: element.createdAt,
                            updatedAt: element.updatedAt,
                            minImageWidth: element.minImageWidth,
                            minImageHeight: element.minImageHeight
                        };
                        _products.push(newProduct);
                    });

                    setProducts(_products);
                });
        }
    }, [auth?.sessionToken, auth?.userId])

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()
        let baseURL = process.env.REACT_APP_BASE_URL;

        const formData = {
            name: (projectnameRef.current == null) ? "" : projectnameRef.current.value,
            product: {
                __type: "Pointer",
                className: "Product",
                objectId: formValues.product
            },
            description: (descriptionRef.current == null) ? "" : descriptionRef.current.value,
            maxVotes: formValues.maxVotes,
            maxUploads: formValues.maxUploads,
            startDate: {
                __type: "Date",
                iso: new Date(dateStates.start)
            },
            endDate: {
                __type: "Date",
                iso: new Date(dateStates.end)
            },
            voteStartDate: {
                __type: "Date",
                iso: new Date(formValues.voteStartDate)
            }
        }

        setError("")
        setLoading(true)

        if (projectnameRef.current) {
            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Parse-Application-Id': process.env.REACT_APP_APPLICATION_ID ?? "null",
                    'X-Parse-Session-Token': auth?.sessionToken ?? "null"
                },
                body: JSON.stringify(formData)
            };
            fetch(baseURL + `/parse/classes/Project`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    if (auth?.sessionToken && auth?.userId) {
                        const headers = {
                            'Content-Type': 'application/json',
                            'X-Parse-Application-Id': process.env.REACT_APP_APPLICATION_ID ?? "null",
                            'X-Parse-Session-Token': auth?.sessionToken ?? "null"
                        }
                        fetch(process.env.REACT_APP_BASE_URL + `/parse/classes/Project/${encodeURIComponent(data.objectId)}`, { headers })
                            .then(response => response.json())
                            .then(data => {
                                history.push(`/projects/${encodeURIComponent(data.objectId)}/campaign/${encodeURIComponent(data.campaign?.objectId || "null")}`)
                            });
                    }
                })
                .catch(err => {
                    setError(t("projectadd.error_addingproject"))
                    setLoading(false)
                    console.error('There was an error!', err)
                });
        } else {
            setError(t("projectadd.error_projectempty"))
            setLoading(false)
        }
    }

    function handleProductChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.persist()
        const target = event.target;
        const value = target.value;
        const name = target.name;
        setFormValues({ ...formValues, [name]: value })
        setFormState({ ...formState, product: true })
        //refreshButtonStates()
    }

    function handleNumberChange(event: React.ChangeEvent<HTMLInputElement>) {
        event.persist()
        const target = event.target;
        const value: number = Number(target.value);
        const name = target.name;
        setFormValues({ ...formValues, [name]: value })
        //refreshButtonStates()
    }


    // function handleDateRangeSelect(value: ReactDateRangePicker.OnSelectCallbackParam) {
    //     //setDateRange(moment.range(value.start, value.end))
    //     setDateStates({ start: value.start.toString(), end: value.end.toString() })
    //     const voteStart = value.end
    //     setFormValues({ ...formValues, voteStartDate: voteStart.subtract((value.end.diff(value.start, 'days') / 2), 'days').toISOString() })
    //     //refreshButtonStates()
    // }

    return (
        <div className="ProjectAdd">
            <TopNavigation />
            <div className="Layer px-4">
                {error && <Alert variant="danger">{error}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <div className={(addState === 0) ? "" : "hidden"}>
                        <ProjectAddType products={products} onChange={handleProductChange} />
                    </div>
                    <div className={(addState === 0) ? "hidden" : ""}>
                        <ProjectAddData
                            projectnameRef={projectnameRef}
                            descriptionRef={descriptionRef}
                            maxVotes={formValues.maxVotes}
                            maxUploads={formValues.maxUploads}
                            onChange={handleNumberChange}
                        />
                    </div>
                    <div className="navigation mt-3 text-center">
                        <Button id="carouselPrev"
                            //className={"btn btn-primary float-left"+(buttonStates.prev?"":" hidden")} 
                            className={(addState===0)?"btn btn-primary float-left rounded-pill hidden":"btn btn-primary float-left rounded-pill"}
                            onClick={(e) => {
                                setAddState(0)
                            }}> {t("general.prev")}</Button>
                        <Button id="carouselNext"
                            className={(addState===0)?"btn btn-primary float-right rounded-pill":"btn btn-primary float-right rounded-pill hidden"}
                            disabled={!formState.product}
                            onClick={(e) => {
                                setAddState(1)
                            }}> {t("general.next")}</Button>
                        <Link id="cancel"
                            className="btn btn-primary rounded-pill"
                            to="/dashboard/"
                        > {t("general.cancel")}</Link>
                        <Button id="formSubmit" disabled={loading}
                            className={(addState===0)?"btn btn-primary float-right rounded-pill hidden":"btn btn-primary float-right rounded-pill"}
                            type="submit">
                            {t("projectadd.add")}
                        </Button>
                    </div>
                </Form>
            </div>
        </div>
    )
}

export default ProjectAdd
