import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next"
import { useAuth } from '../contexts/AuthContext';
import { useHistory } from "react-router-dom"

function GoogleAuth() {
    const { t } = useTranslation()
    const auth = useAuth()
    const history = useHistory()
    const [state, setState] = useState<string | null>("")
    const [token, setToken] = useState<string | null>("")

    useEffect(() => {
        var hash = window.location.hash.substr(1)
        var urlsp = new URLSearchParams(hash)
        if (urlsp.has("state"))
            setState(urlsp.get("state"));
        if (urlsp.has("id_token"))
            setToken(urlsp.get("id_token"));
    }, [])

    useEffect(() => {
        if (state && token) {
            let baseURL = process.env.REACT_APP_BASE_URL;

            const formData = {
                state: state,
                token: token
            }

            const requestOptions = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'X-Parse-Application-Id': process.env.REACT_APP_APPLICATION_ID ?? "null"
                },
                body: JSON.stringify(formData)
            };
            fetch(baseURL + `/parse/functions/signInWithGoogle`, requestOptions)
                .then(response => response.json())
                .then(data => {
                    let redirectTo = data.result.redirectTo
                    let username = data.result.user.objectId
                    let sessionToken = data.result.user.sessionToken
                    auth?.persistSession(username, sessionToken)
                    history.push(redirectTo)
                })
                .catch(err => {
                    console.error('There was an error!', err)
                })
        }
    }, [auth, history, state, token])

    return (
        <>
            <div className="Layer p-4">
                <h2>{t("googleauth.heading")}</h2>
                <p>{t("googleauth.redirecting")}</p>
            </div>
        </>
    )
}

export default GoogleAuth
