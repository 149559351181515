import React from 'react'
import {Form } from "react-bootstrap"
import DateRangePicker from "react-daterange-picker";
import * as MomentRange from "moment-range";
import Moment from "moment";
import 'react-daterange-picker/dist/css/react-calendar.css'
import "react-datepicker/dist/react-datepicker.css"
import DatePicker from 'react-datepicker';
import { useTranslation } from 'react-i18next';

interface Props {
    startDate: string
    endDate: string
    voteStartDate: string
    dateRange: MomentRange.DateRange
    onSelect(value: ReactDateRangePicker.OnSelectCallbackParam): void
    onChangeVote(date:Date): void
}

function ProjectAddDates({startDate, endDate, voteStartDate, dateRange, onSelect, onChangeVote}:Props) {
    Moment.locale('de');
    const { t } = useTranslation()
    return (
        <>
            <div className="item">
                <h1 className="text-center">{t("projectadd.datestitle")}</h1>
                <div>{}</div>
                <DateRangePicker 
                    onSelect={onSelect}
                    locale="de"
                    numberOfCalendars={1}
                    selectionType="range"
                    minimumDate={new Date()}
                    value={dateRange}
                />
                <Form.Group className="mb-3 d-flex">
                    <Form.Label>{t("projectadd.daterange")}</Form.Label>
                    <Form.Control type="text" name="startDate" 
                        className="width-50"
                        readOnly={true} 
                        value={Moment(startDate).format('DD.MM.YYYY')+" - "+Moment(endDate).format('DD.MM.YYYY')}
                        required />
                </Form.Group>
                <Form.Group className="mb-3">
                    <Form.Label>{t("projectadd.votingDate")}</Form.Label>
                    <DatePicker 
                        popperPlacement="top-start" 
                        name="voteStartDate" 
                        minDate={new Date(startDate)}
                        maxDate={new Date(endDate)}
                        selected={new Date(voteStartDate)} 
                        onChange={onChangeVote} 
                        dateFormat="dd.MM.yyyy"
                        />
                </Form.Group>
            </div>        
        </>
    )
}

export default ProjectAddDates


