import React, { useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { Form, Button, Alert } from "react-bootstrap"
import { Link } from "react-router-dom"
import printomioLogo from '../img/logo.png';

function ForgotPassword() {
    const { t } = useTranslation()
    const emailRef = useRef<HTMLInputElement>(null)
    const [error, setError] = useState("")
    const [message, setMessage] = useState("")
    const [loading, setLoading] = useState(false)

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()

        setMessage("")
        setError("")
        setLoading(true)

        if (emailRef.current) {
            var emailRE = /\S+@\S+\.\S+/;
            if (!emailRE.test(emailRef.current.value)) {
                setLoading(false)
                setMessage(t("forgotpassword.please_enter_email"))
            }

            try {
                let baseURL = process.env.REACT_APP_BASE_URL;

                const formData = {
                    email: emailRef.current.value
                }

                const requestOptions = {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'X-Parse-Application-Id': process.env.REACT_APP_APPLICATION_ID ?? "null"
                    },
                    body: JSON.stringify(formData)
                };
                await fetch(baseURL + `/parse/functions/resetPassword`, requestOptions)
                    .then(response => {
                        if (response.ok) {
                            setLoading(false)
                            setMessage(t("forgotpassword.check_inbox"))
                            response.json().then(json => {
                                console.log(json.result);
                            });
                        }
                    })
                    .catch(err => {
                        setError(t("forgotpassword.error"))
                        setLoading(false)
                        console.error('There was an error!', err)
                    });
            } catch {
                setError(t("forgotpassword.error"))
            }
        }

        setLoading(false)
    }

    return (
        <>
            <div className="px-4">
                <img className="logo" style={{ maxWidth: "300px", margin: "0 auto", display: "block" }} src={printomioLogo} alt="Printomio - Social Printing" />
            </div>
            <div className="Layer px-4">
                {error && <Alert variant="danger">{error}</Alert>}
                {message && <Alert variant="success">{message}</Alert>}
                <Form onSubmit={handleSubmit}>
                    <Form.Group id="email" className="mb-3">
                        <Form.Control type="email" ref={emailRef} placeholder="E-Mail" required />
                    </Form.Group>
                    <Button disabled={loading} className="w-100" type="submit">
                        {t("forgotpassword.reset_password")}
                    </Button>
                </Form>
                <div className="w-100 text-center mt-3">
                    <Link to="/login">{t("forgotpassword.login_here")}</Link>
                </div>
                <div className="w-100 text-center mt-2">
                    {t("forgotpassword.no_account_yet")} <Link to="/signup">{t("forgotpassword.register_now")}</Link>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword
