import React, { useRef, useState } from "react"
import { Form, Button, Alert } from "react-bootstrap"
import { AuthContext } from '../contexts/AuthContext'
import { Link, useHistory, useLocation } from "react-router-dom"
import printomioLogo from '../img/logo.png'
import { useTranslation } from "react-i18next"
import RegistrationOptions from "./RegistrationOptions"

// A custom hook that builds on useLocation to parse the query string
function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

function Login() {
    const { t } = useTranslation()
    const emailRef = useRef<HTMLInputElement>(null)
    const passwordRef = useRef<HTMLInputElement>(null)
    const { login } = React.useContext(AuthContext) as AuthContextType
    const [error, setError] = useState("")
    const [loading, setLoading] = useState(false)
    const history = useHistory()
    let query = useQuery();

    async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()

        setError("")
        setLoading(true)

        if (emailRef.current && passwordRef.current) {
            try {
                await login(emailRef.current.value, passwordRef.current.value)
                //Build relative campaign URL
                let appURL = process.env.REACT_APP_LOCAL_BASE_URL || "undefined";
                history.push(query.get("redirect-url")?.replace(appURL, '') || "/dashboard")
            } catch {
                setLoading(false)
                setError(t("login.error"))
            }
        }
    }

    return (
        <>
            <div className="px-4">
                <a href="/"><img className="logo" style={{ maxWidth: "300px", margin: "0 auto", display: "block" }} src={printomioLogo} alt="Printomio - Social Printing" /></a>
            </div>
            <div className="Layer px-4">
                {error && <Alert variant="danger">{error}</Alert>}

                <Form onSubmit={handleSubmit}>
                    <Form.Group id="email" className="mb-3">
                        <Form.Control type="email" ref={emailRef} placeholder={t("login.email")} required />
                    </Form.Group>
                    <Form.Group id="password" className="mb-3">
                        <Form.Control type="password" ref={passwordRef} placeholder={t("login.password")} required />
                    </Form.Group>
                    <Button disabled={loading} className="btn btn-primary mb-3 rounded-pill" type="submit">
                        {t("login.login")}
                    </Button>
                </Form>
                <div className="w-100 text-center mt-3">
                    <Link to="/forgot-password">{t("login.password_lost")}</Link>
                </div>
                <RegistrationOptions campaignURL={query.get("redirect-url") || "/dashboard"} />
            </div>
        </>
    )
}

export default Login

