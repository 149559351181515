import React, { useState, useEffect } from 'react'
import { Row, Col, Modal, Button } from "react-bootstrap"
import { useAuth } from '../contexts/AuthContext'
import { Link } from "react-router-dom"
import axios from 'axios'
import ProjectList from './ProjectList'
import TopNavigation from './TopNavigation'
import { RiAddFill } from 'react-icons/ri'
import { useTranslation } from 'react-i18next';
import '../css/Dashboard.css'
import { AiOutlineInfo } from 'react-icons/ai'

function Dashboard() {
    const auth = useAuth();
    const [projects, setProjects] = useState<IProjectData[]>(new Array<IProjectData>())
    const { t } = useTranslation()

    //Having the projects only updated when the sessionToken or userId changes
    useEffect(() => {
        if (auth?.sessionToken && auth?.userId) {
            axios.get(process.env.REACT_APP_BASE_URL + `/parse/classes/Project?where={"user":{"__type":"Pointer","className":"_User","objectId":"${encodeURIComponent(auth.userId)}"}}`, {
                headers: {
                    'X-Parse-Application-Id': process.env.REACT_APP_APPLICATION_ID,
                    'X-Parse-Session-Token': auth.sessionToken
                }
            })
                .then(res => {
                    var projects = new Array<IProjectData>();

                    res.data.results.forEach((element: any) => {

                        const newProject: IProjectData = {
                            objectId: element.objectId,
                            userId: element.user?.objectId,
                            name: element.name,
                            description: element.description,
                            createdAt: element.createdAt,
                            updatedAt: element.updatedAt,
                            campaignId: element.campaign?.objectId,
                            productId: element.product?.objectId,
                            maxVotes: element.maxVotes,
                            maxUploads: element.maxUploads,
                            startDate: element.startDate ? element.startDate.iso : "",
                            endDate: element.endDate ? element.endDate.iso : "",
                            voteStartDate: element.voteStartDate ? element.voteStartDate.iso : ""
                        };
                        projects.push(newProject);
                    });

                    setProjects(projects);
                });
        }
    }, [auth?.sessionToken, auth?.userId])
    const [show, setShow] = useState(false)
    const handleClose = () => setShow(false)
    const handleShow = () => setShow(true)

    return (
        <>
            <TopNavigation />
            <div className="Layer p-4">
                <Row>
                    <Col className="dashboard-new">
                        <Button className="info btn btn-secondary rounded-pill" onClick={handleShow}><AiOutlineInfo /></Button>
                        <Link className="new btn btn-secondary rounded-pill" title={t("dashboard.newproject")} to="/projects/add"><RiAddFill /> {t("dashboard.newproject")}</Link>
                    </Col>
                </Row>
                <Row className="mt-2 mb-2 align-items-center">
                    <Col xs="10"><h2>{t("dashboard.projectlist")}:</h2></Col>
                    <Col className="text-end" xs="2"></Col>
                </Row>
                <ProjectList projects={projects} />
                <Row>
                    <Col><h2>{t("dashboard.sharedprojectlist")}:</h2></Col>
                </Row>
                <Row>
                </Row>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton={false}>
                <Modal.Title>{t("dashboard.infotitle")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>{t("dashboard.infotext")}</Modal.Body>
                <Modal.Footer>
                    <Button className="btn btn-primary" onClick={handleClose}>
                        Schließen
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Dashboard
