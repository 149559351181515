import { Redirect, Route, RouteProps } from 'react-router-dom'
import { useAuth } from '../contexts/AuthContext'

interface PrivateRouteProps extends RouteProps { }

/**
 * Extends 'Route' from react-router-dom to make them available only for authenticated users.
 * Depends on AuthContext
 * @param param0 
 * @returns 
 */
const PrivateRoute: React.FC<PrivateRouteProps> = ({ ...rest }) => {
    const auth = useAuth()

    return (auth?.sessionToken && auth?.userId) ? <Route {...rest} /> : <Redirect to="/login" />
}

export default PrivateRoute
