import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth } from '../contexts/AuthContext'
import { Alert, ProgressBar } from 'react-bootstrap'
import { FiTrash2, FiCheckCircle, FiXCircle } from "react-icons/fi"
import fileSize from 'filesize'

export interface FileUploadItemWithProgressProps {
    fileWrapper: UploadableFile;
    campaignId: string;
    onRemove: (file: UploadableFile) => void;
    onUploadCompleted: (file: UploadableFile) => void;
}

function FileUploadItemWithProgress({
    fileWrapper,
    campaignId,
    onRemove,
    onUploadCompleted
}: FileUploadItemWithProgressProps) {
    const auth = useAuth()
    const { t } = useTranslation()
    const [progress, setProgress] = useState(0);
    const [uploadInProgress, setUploadInProgress] = useState(false);
    const [uploadSucceeded, setUploadSucceeded] = useState(false);

    function remove(e: any) {
        onRemove(fileWrapper);
    }

    useEffect(() => {
        async function upload() {
            setUploadInProgress(true);

            await uploadFile(fileWrapper.file, campaignId, auth?.sessionToken ?? "null", setProgress)
                .then(url => {
                    setUploadSucceeded(true);
                    setUploadInProgress(false);
                    fileWrapper.isUploadCompleted = true;
                    onUploadCompleted(fileWrapper);
                })
                .catch(response => {
                    setUploadSucceeded(false);
                    setUploadInProgress(false);
                    fileWrapper.isUploadFailed = true;
                });
        }

        if (fileWrapper.isStarted) {
            upload();
        }
    }, [fileWrapper.isStarted, fileWrapper.file.name, campaignId, auth?.sessionToken]);

    return (
        fileWrapper.errors.length ? (
            <Alert variant="danger" className="alert-upload-error">
                <div className="error-messages">
                    {fileWrapper.file.name}
                    <hr />
                    <ul>
                        {fileWrapper.errors.map((error) => (<li>{t('campaigndetail.uploaderror_' + error.code)}</li>))}
                    </ul>
                </div>
                <div className="right-control-section" onClick={remove}>
                    <FiTrash2 />
                </div>
            </Alert>
        ) : (
            <div className="file-upload-container">
                <div className="circular-fit">
                    <img src={URL.createObjectURL(fileWrapper.file)} alt={fileWrapper.file.name} width="70px" height="70px" />
                </div>
                <div className="progress-section">
                    <div className="file-label">
                        <span className="file-name">{fileWrapper.file.name}</span> <span className="filesize-label">{fileSize(fileWrapper.file.size)}</span>
                    </div>
                    {fileWrapper.isStarted && <ProgressBar animated={uploadInProgress} now={progress} label={`${progress}%`} className={`${fileWrapper.isUploadFailed ? "upload-failed" : ""}`} />}
                </div>
                {!fileWrapper.isStarted && <div className="right-control-section" onClick={remove}>
                    <FiTrash2 />
                </div>}
                {uploadSucceeded && !fileWrapper.isUploadFailed && <div className="right-control-section" onClick={remove}>
                    <FiCheckCircle className="checkcircle-green" />
                </div>}
                {fileWrapper.isUploadFailed && <div className="right-control-section" onClick={remove}>
                    <FiXCircle className="fixcircle-error" />
                </div>}
            </div>
        )
    )
}

function uploadFile(file: File, campaignId: string, sessionToken: string, onProgress: (percentage: number) => void) {
    const url = process.env.REACT_APP_BASE_URL + `/parse/files/${encodeURIComponent(campaignId)}_${encodeURIComponent(file.name)}`;

    return new Promise<string>((res, rej) => {
        const xhr = new XMLHttpRequest();
        xhr.open('POST', url);
        xhr.setRequestHeader("X-Parse-Application-Id", process.env.REACT_APP_APPLICATION_ID ?? "null");
        xhr.setRequestHeader("X-Parse-Session-Token", sessionToken);
        xhr.setRequestHeader("Content-Type", file.type);

        xhr.onload = (event) => {
            /**
             * We need to check for a HTTP status code 201 (created) in order to be sure that the request has been
             * handled successfully.
             * If for example the max. image uploads are reached, the HTTP status code woult be 400, while the
             * request would be technically still successfull.
             */
            if (xhr.status === 201) {
                const resp = JSON.parse(xhr.responseText);
                res(resp.url);
            } else {
                //Force rejected promise due to HTTP status code does not indicate succcedd (201 - created)
                rej(JSON.parse(xhr.responseText));
            }
        };
        xhr.onerror = (evt) => rej(evt);
        xhr.upload.onprogress = (event) => {
            if (event.lengthComputable) {
                const percentage = (event.loaded / event.total) * 100;
                onProgress(Math.round(percentage));
            }
        };

        xhr.send(file);
    });
}

export default FileUploadItemWithProgress
