import { Row, Col, Form } from "react-bootstrap"
import { FiCalendar } from 'react-icons/fi'
import { useTranslation } from 'react-i18next';

interface Props {
    products: IProductData[]
    onChange(event: React.ChangeEvent<HTMLInputElement>): void
}

function ProjectAddType({ products, onChange }: Props) {
    const { t } = useTranslation()
    return (
        <>
            <div className="item">
                <h1 className="text-center">{t("projectadd.typetitle")}</h1>
                <Row>
                    {products.map((product: IProductData) => (
                        <Col md="6" sm="6" key={product.objectId}>
                            <Form.Check type="radio" id={"check-api-radio" + product.objectId} className="p-0 w-100">
                                <Form.Check.Input name="product" type="radio" value={product.objectId} onChange={onChange} className="hidden" required />
                                <Form.Check.Label className="w-100">
                                    <Row className="product">
                                        <Col xs="6" className={"product product-" + product.objectId}>

                                        </Col>
                                        <Col xs="6" className="productInfo">
                                            <FiCalendar className="typeSelect" /><br />
                                            {product.name}
                                        </Col>
                                    </Row>
                                </Form.Check.Label>
                            </Form.Check>
                        </Col>
                    ))}
                </Row>
            </div>

        </>
    )
}

export default ProjectAddType
