import { useTranslation } from "react-i18next"
import { Form } from "react-bootstrap"

type AppProps = {
    projectnameRef: React.RefObject<HTMLInputElement>
    descriptionRef: React.RefObject<HTMLTextAreaElement>
    maxVotes: number
    maxUploads: number
    onChange(event:React.ChangeEvent<HTMLInputElement>): void
  };
function ProjectAddData( {projectnameRef, descriptionRef, maxVotes, maxUploads, onChange}: AppProps) {
    const { t } = useTranslation()
    return (
        <>
        <div className="item">
            <h1 className="text-center">{t("projectadd.datatitle")}</h1>
            <Form.Group id="projectname" className="mb-3">
                <Form.Control type="text" name="name" ref={projectnameRef} placeholder={t("projectadd.projectname")} required />
                <Form.Control as="textarea" name ="description" ref={descriptionRef} rows={3} placeholder={t("projectadd.projectdescription")}></Form.Control>
            </Form.Group>
        </div>
        </>
    )
}

export default ProjectAddData
